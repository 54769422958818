import React from 'react'
import SEO from '../components/SEO'
import Container from '../components/Container'
import {graphql, useStaticQuery} from 'gatsby'
import Layout from '../components/Layout'
//import Hero from '../components/Hero'
import styled from '@emotion/styled'
import {keyframes} from '@emotion/core'


const slidein = keyframes`
    0% {
      opacity:0
    }
   
    99% {
      opacity:1;
    }
  }`
  const MyArt = styled("div")`
  flex-direction:column;
  justify-content:flex-start;
   width:48%;
   h2 {
    font-size:1.6rem;
    font-weight:500;
    padding:5px;
    text-align:left;
    font-family:Roboto;
  
  }
 
  
   @media(max-width:768px){
    width:100%;

  }

  @media(max-width:769px){
    h2 {
      font-size:1.3rem;
      font-weight:500;
      
    }
  }
  
  
  `

  
    const MyDiv = styled("div")`
   display:flex;
   flex-wrap:wrap;
   justify-content:flex-start;
   width:100%;
   margin:20px 0;
    
   @media(max-width:768px){
   flex-direction:column;
  }
  
 p {
   font-size:1rem;
   padding:5px;
 }
 .responsive-video iframe {
 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  }

  .responsive-video {
    position: relative;
    padding-bottom: 56.25%; /* Default for 1600x900 videos 16:9 ratio*/
    padding-top:0;
    height: 0;
    overflow: hidden;
    @media(min-width:769px){
      margin-top:40px;
    }
    }
 animation: ${slidein} 1s ease;
 @media(max-width:796px){
   flex-direction:column;
 }
`

const Video = () => {
    
 
    const data = useStaticQuery(graphql`
    
    query {
        site {
          ...site
          siteMetadata {
            title
          }
        }
     
    }
    `)
    return(
        <Layout site={data.site}>
          <SEO title="Videos von Markt 1"
          description="Videos von Ausstellung und Aktionen des Kulturraums Markt 1"/>
          <Container nohorizontalpadding>
        
          <MyDiv>
      <MyArt>
      <p> A U S S T E L L U N G &nbsp; N° 5</p>
      
            <h2>   
    Weltenfrauen. Im Gewand der Vielfalt.
       
          </h2>
          <p>Ein Projekt der Künstlerin und Fotografin Ellen Schmauss<br/>
            © Markt 1 // 2021 <br/> </p>  
            </MyArt>
            <MyArt>
            <div className="responsive-video">
            <iframe src="https://player.vimeo.com/video/519580321" width="1300" height="400" frameborder="0" allow="autoplay; fullscreen"
             allowfullscreen
             title="Weltenfrauen. Im Gewand der Vielfalt"
             aria-label="Weltenfrauen. Im Gewand der Vielfalt"
             >

            </iframe>
            </div>
         </MyArt>
</MyDiv>
<hr></hr>
          <MyDiv>
      <MyArt>
      <p> A U S S T E L L U N G &nbsp; N° 4</p>
      
            <h2>   
    After Turner & The 7th Day
       
          </h2>
          <p>Vortrag von Patrick Zajfert zu Lochkamerabildern des Schülerprojekts After Turner sowie das Langzeitprojekt The 7th Day<br/>
            © Markt 1 // 2020 <br/> </p>  
            </MyArt>
            <MyArt>
            <div className="responsive-video">
            <iframe src="https://player.vimeo.com/video/474241389" width="1300" height="400" frameborder="0" allow="autoplay; fullscreen"
             allowfullscreen
             title="Camera Obscura"
             aria-label="Camera Obscura"
             >

            </iframe>
            </div>
         </MyArt>
</MyDiv>

          <MyDiv>
      <MyArt>
      <p> A U S S T E L L U N G &nbsp; N° 3</p>
      
            <h2>   
      Minimalbehausung Hüttchen
       
          </h2>
          <p>1. Das Projekt Minimalbehausung der Universität Mainz unter Leitung von Prof. Kerstin Molter, Fachbereich Architektur. Ein Projekt im Rahmen der BUGA 2029.<br/>
          Vortrag von Prof. Kerstin Molter<br/>
            © Markt 1 // 2020 <br/> </p>  
            </MyArt>
            <MyArt>
            <div className="responsive-video">
            <iframe src="https://player.vimeo.com/video/448511557" width="1300" height="400" frameborder="0" allow="autoplay; fullscreen"
             allowfullscreen
             title="Minimalbehausung Hüttchen. Ein Projekt von Prof. Molter."
             aria-label="Minimalbehausung Hüttchen"
             >

            </iframe>
            </div>
         </MyArt>
</MyDiv>


<MyDiv>
      <MyArt>
  
      
            <h2>   
      Minimalbehausung Hüttchen. Das Energiekonzept.
       
          </h2>
          <p>3. Das Projekt Minimalbehausung der Universität Mainz unter Leitung von Prof. Kerstin Molter, Fachbereich Architektur. Ein Projekt im Rahmen der BUGA 2029.<br/>Das Energiekonzept<br/>
            © Markt 1 // 2020 <br/> </p>  
            </MyArt>
            <MyArt>
            <div className="responsive-video">
            <iframe src="https://player.vimeo.com/video/449191151" width="1300" height="400" frameborder="0" allow="autoplay; fullscreen"
             allowfullscreen
             title="Minimalbehausung Hüttchen. Ein Projekt von Prof. Molter."
             aria-label="Minimalbehausung Hüttchen"
             >

            </iframe>
            </div>
         </MyArt>
</MyDiv>
<hr></hr>
          <MyDiv>
      <MyArt>
    
      <p> A U S S T E L L U N G &nbsp; N° 2</p>
            <h2>   
       Finissage Sketch Walk 2020
       
          </h2>
          <p>Der letzte Tag mit den Urban Sketchers, u.a ein Ausflug auf die Insel Heyles'en Werth,
          begleitet von einem Fernsehteam des SWR. <br/>Video von René Broich
          
            © Markt 1 // 2020 <br/> </p>  
            </MyArt>
            <MyArt>
            <div className="responsive-video">
            <iframe src="https://player.vimeo.com/video/442171264" width="1300" height="400" frameborder="0" allow="autoplay; fullscreen"
             allowfullscreen
             title="Finissage Sketch Walk 2020"
             aria-label="Finissage Sketch Walk 2020"
             >

            </iframe>
            </div>
         </MyArt>
</MyDiv>

          <MyDiv>
      <MyArt>

    
            <h2>   
       Urban Sketch Voyage 2.0 <br/> 
        Online-Event 9.6.2020<br/>
          </h2>
           <p>  © Markt 1 // 2020, <br/>Sprecher: Benoît Carrié </p>  
            </MyArt>
            <MyArt>
            <div className="responsive-video">
            <iframe src="https://player.vimeo.com/video/430945465" width="1300" height="400" frameborder="0" allow="autoplay; fullscreen"
             allowfullscreen
             title="Urban Sketch Voyage 2.0 – Online-Event"
             aria-label="Urban Sketch Voyage 2.0 – Online-Event"
             >

            </iframe>
            </div>
         </MyArt>
</MyDiv>

<MyDiv>
      <MyArt>

      
            <h2>   
       Urban Sketch Voyage 2.0 
       
          </h2>
          <p>Vorbereitung &nbsp; USK - Rhein-Main<br/>
            © Markt 1 // 2020 </p>  
            </MyArt>
            <MyArt>
            <div className="responsive-video">
            <iframe src="https://player.vimeo.com/video/431719178" width="1300" height="400" frameborder="0" allow="autoplay; fullscreen"
             allowfullscreen
             title="Vorbereitung: USK – Voyage 2.0"
             aria-label="Vorbereitung: USK – Voyage 2.0"
             >

            </iframe>
            </div>
         </MyArt>
</MyDiv>
<hr></hr>
          <MyDiv>
      <MyArt>
            
      <p> A U S S T E L L U N G &nbsp; N° 1</p>
            <h2>   
        Theaterfestival 2017. <br/>An den Ufern der Poesie. <br/>
        Der Rabbi von Bacherach.<br/>
      
              </h2>
              <p> Video von René Broich</p>
            </MyArt>
            <MyArt>
            <div className="responsive-video">
            <iframe src="https://player.vimeo.com/video/426366576" width="1300" height="400" frameborder="0" allow="autoplay; fullscreen"
             allowfullscreen
             title="Theaterfestival 2017. An den Ufern der Poesie."
             aria-label="Theaterfestival 2017. An den Ufern der Poesie"
             >

            </iframe>
            </div>
         </MyArt>
</MyDiv>
          <MyDiv>
      <MyArt>
            <h2>   
        Stimmen zum Theaterfestival 2019 <br/>
        An den Ufern der Poesie.<br/>
             </h2>
             <p> Video von René Broich</p>
            </MyArt>
            <MyArt>
            <div className="responsive-video">
            <iframe src="https://player.vimeo.com/video/425069516" width="1300" height="400" frameborder="0" allow="autoplay; fullscreen"
             allowfullscreen
             title="Stimmen zum Theaterfestival 2019 im Oberen Mittelrheintal"
             aria-label="Stimmen zum Theaterfestival 2019 im Oberen Mittelrheintal"
             >

            </iframe>
            </div>
         </MyArt>
</MyDiv>
<MyDiv>
      <MyArt>
            <h2>   
        Franz Schubert: <br/>
        Die schöne Müllerin.<br/>
       Veranstaltungsort Kaub.
              </h2>
              <p>
            Akkordeon: Matthias Matzke // Gesang: Graham F. Valentine // Doppelgänger Muawia Harb //
            Regie: Willy Praml</p>
            </MyArt>
            <MyArt>
            <div className="responsive-video">

           
            <iframe src="https://player.vimeo.com/video/425074191" width="1300" height="400" frameborder="0" allow="autoplay; fullscreen"
            
             title="Franz Schubert. Die schöne Müllerin"
             aria-label="Franz Schubert. Die schöne Müllerin"
             >

            </iframe>
            </div>
         </MyArt>
</MyDiv>
    <MyDiv>
      <MyArt>
          <h2>
              Guck mal, Michael Apitz 
            </h2>
            <p>
              Publikumsandrang kurz vor der Vollendung des auf der Bacchanale live
              von Michael Apitz gemalten Bildes der Werner-Kapelle
            </p>
      </MyArt>
     <MyArt>
           <div className="responsive-video">
              <iframe src="https://player.vimeo.com/video/415422499" width="1300" height="500" frameborder="0" allow="autoplay; fullscreen" 
              allowfullscreen
              title="Guck mal, Michael Apitz"
              aria-label="Guck mal, Michael Apitz"
              ></iframe>
             </div>

      </MyArt>
    </MyDiv> 
    <MyDiv>
       <MyArt>
          <h2>
              Bacchanale Finale
            </h2>
            <p>
              Gesang: Hippie-Gemeinde 'Blaue Blume 1965' (Durchschnittsalter 75 Jahre) //
              Ort: Marktplatz in Bacharach
            </p>
      </MyArt>
      <MyArt>
          <div className="responsive-video">
              <iframe 
              src="https://player.vimeo.com/video/412861964" width="1300" height="500" 
              frameborder="0" allow="autoplay; fullscreen" 
              allowfullscreen
              title="Bacchanale Finale" 
              aria-label="Bacchanale Finale"
              >         
          </iframe>
          </div>
       </MyArt>
  </MyDiv>
  <MyDiv>
      <MyArt>
         <h2>
          Nina Seipel am Saxophon 
           </h2>
           <p>
           Aus der Garage an der Oberstraße heraus klingt Ninas Saxophon zu den Besuchern der Bacchanale.
          </p>
         </MyArt>
         <MyArt>
           <div className="responsive-video">
           <iframe src="https://player.vimeo.com/video/415423279" width="1300" height="500" frameborder="0" allow="autoplay; fullscreen" 
           allowfullscreen
            title="Nina Seipel am Saxophon"
            aria-label="Nina Seipel am Saxophon"
           ></iframe>
         </div>

      </MyArt>
  </MyDiv> 
   <MyDiv>
       <MyArt>
          <h2>
              Lesebühne Schiefertafel
            </h2>
            <p>
              Gesang: Hilarion Hartmann, Helga Andrae //
             Gitarre: Hilarion Hartmann
            </p>
          </MyArt>
          <MyArt>
          <div className="responsive-video">
          <iframe src="https://player.vimeo.com/video/412848523" width="1300" height="500" 
          frameborder="0" allow="autoplay; fullscreen" 
          allowFullScreen
            title="Lesebühne Bacharach. Helga Andrae"
            aria-label="Lesebühne Bacharach. Helga Andrae"
          ></iframe>
          </div>
       </MyArt>
    </MyDiv>
    <MyDiv>
      <MyArt>
         <h2>
         Kein Haus, keine Heimat <br/>Brahms Op. 63 N° 5
           </h2>
           <p>
           Gesang: Henriette Meyer-Ravenstein, Mezzosopran // Musik: Elisabeth Boström, Klavier // Bild &amp; Idee: Katrin Gloggengießer
           </p>
        </MyArt>
         <MyArt>
           <div className="responsive-video">
            <iframe src="https://player.vimeo.com/video/412839979" width="1300" height="500" 
            aria-label="Film Kein Haus, keine Heimat // Brahms Op. 63 N° 5"
            title="Kein Haus, keine Heimat // Brahms Op. 63 N° 5"
            frameborder="0" allow="autoplay; fullscreen" allowfullscreen>
            </iframe>
          </div>
         </MyArt>
  </MyDiv>
   <MyDiv>
      <MyArt>
         <h2>
         Cinema Paradiso
           </h2>
           <p>
           Super-8-Filmmaterial von Bacharacher Bürgern collagiert, bearbeitet und zusammengestellt von Sandra Meurer // Musik: Gregor Praml – Kontrabass und loopmachine
           </p>
         </MyArt>
         <MyArt>
         <div className="responsive-video">
         <iframe src="https://player.vimeo.com/video/412872820" width="1300" height="500" frameborder="0" allow="autoplay; fullscreen" 
         title="Cinema Paradiso"
         aria-label="Cinema Paradiso"
         allowfullscreen></iframe>
         </div>
      </MyArt>
    </MyDiv>
    <MyDiv>
      <MyArt>
         <h2>
         Wie ich das Bahnproblem gelöst habe.
           </h2>
           <p>
             Ein aufschlussreicher Vortrag von Rene Broich aus der Serie "Leben und Arbeiten im Oberen Mittelrheintal", gehalten auf der Bacchanale 2019"
           </p>
         </MyArt>
         <MyArt>
         <div className="responsive-video">
         <iframe src="https://player.vimeo.com/video/413131337" width="1300" height="500" frameborder="0" allow="autoplay; fullscreen" 
         title="Wie ich das Bahnproblem löste"
         aria-label="Wie ich das Bahnproblem löste"
         allowfullscreen></iframe>
         </div>
      </MyArt>
    </MyDiv>
    <MyDiv>
         <MyArt>
            <h2>
            Low Actionmovie <br/>Rheinland-Pfalz #01
              </h2>
              <p>
              Musik: Jan Roder – Double Bass ok // Bild & Idee: Katrin Gloggengießer</p>
          </MyArt>
          <MyArt>
            <div className="responsive-video">
              <iframe src="https://player.vimeo.com/video/412866048" width="1300" height="500" frameborder="0" allow="autoplay; fullscreen" 
              title="Low Action Movie No. 1"
              aria-label="Low Action Movie No. 1"
              allowfullscreen></iframe>
            </div>
      </MyArt>
    </MyDiv>
   


 </Container>
        </Layout>
     
    )
}

export default Video











